import Layout from "../components/layout";
import React from "react";

const SelfServiceTools = () => (

    <Layout>
        <section className="section-utility">
            <div className="section-6 wf-section">
                <div data-w-id="fee6c11f-454d-a31b-c936-e96f978ddd39" className="v3-1-hero w-container">
                    <div className="home-announcement-container">
                        <div className="div-block-101">
                            <div className="text-block-73">NEW</div>
                            <img src="/images/6243040d301c232fbcd220f6_Group 2121.svg" loading="lazy" alt="" className="image-95"/>
                        </div>
                        <a data-event-category="Home Announcement Link" data-event-action="Click" data-event-label="Roast My Finance Stack"
                           href="https://www.rebanknow.com/roast-my-finance-stack" className="v3-banner-link w-inline-block">
                            <div className="v3-link-text-small"></div>
                            <img src="/images/6203f94ff8a2e8101731d4c0_arrow_forward.svg" loading="lazy" alt="Arrow icon"
                                 className="v3-benefit-link-arrow"/>
                        </a>
                    </div>
                    <h1 id="v3-hero-heading-original" className="v3-1-hero-heading">Service at your fingertip<br/></h1>
                    <div className="v3-1-hero-text">Seamless access of information, service request and reservations
                        <br/> Anytime during your stay! <br/>
                    </div>
                    <img src="/images/hotel-svg.svg" loading="lazy" alt="" className="image-75"/>
                    <img src="/images/" loading="lazy" alt="" className="image-76"/>
                    <div className="div-block-87">
                    </div>
                </div>
            </div>
            <div className="section-12 wf-section">
                <div className="v3-main w-container">
                    <div className="v3-benefit-row">
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">Experience of hassle free in-house services.</h2>
                            <div className="v3-link-text">
                                The DD Zone self service tools provides you
                               <div><a className=""><br/>Information</a></div>
                                <div><a className=""><br/>Request for room service</a></div>
                                <div><a className=""><br/>Reservations</a></div>
                            </div>
                        </div>
                        <div className="div-block-90">
                            <img src="/images/wifi.svg" loading="lazy" data-w-id="71287bbe-10c2-ccff-15dd-a466ad9a6161" alt="" className="v3-1-benefit-img-1"/>
                            <img src="/images/6214f37bbec7b23bce0ad03b_opportunities-back.svg" loading="lazy" alt="Opportunities backdrop" className="image-61"/>
                        </div>
                    </div>
                    <div className="v3-benefit-row">
                        <div className="div-block-90">
                            <img src="/images/smartphone-info.svg" loading="lazy" data-w-id="b1ee4da0-6177-00ac-9762-5de9c7a6f914" alt="Drone carrying cash across borders" className="v3-benefit-img-2"/>
                            <img src="/images/6214d761fa850257971b09a4_drone-clouds.svg" loading="lazy" alt="Clouds" className="image-62"/>
                        </div>
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">
                                Information</h2>
                            <div className="v3-link-text">
                                We'll ensure you a pleasant choice!<br/>
                                You'll get the information of facilities and services on your mobile screen.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-12 wf-section">
                <div className="v3-main w-container">
                    <div className="v3-benefit-row">
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">Request and order </h2>
                            <div className="v3-link-text">
                                DD Zone’s self-service tool at your service!<br/>
                                You can order and request for housekeeping service, room service(Food, WIFI, TV, Toiletries) with just one click.
                            </div>
                        </div>
                        <div className="div-block-90">
                            <img src="/images/room-service.svg" loading="lazy" data-w-id="71287bbe-10c2-ccff-15dd-a466ad9a6161" alt="" className="v3-1-benefit-img-1"/>
                            <img src="/images/6214f37bbec7b23bce0ad03b_opportunities-back.svg" loading="lazy" alt="Opportunities backdrop" className="image-61"/>
                        </div>
                    </div>
                    <div className="v3-benefit-row">
                        <div className="div-block-90">
                            <img src="/images/reservation-table.svg" loading="lazy" data-w-id="b1ee4da0-6177-00ac-9762-5de9c7a6f914" alt="Drone carrying cash across borders" className="v3-benefit-img-2"/>
                            {/*<img src="/images/6214d761fa850257971b09a4_drone-clouds.svg" loading="lazy" alt="Clouds" className="image-62"/>*/}
                        </div>
                        <div className="div-block-67">
                            <h2 className="v3-benefit-heading">
                                Booking and Reservation</h2>
                            <div className="v3-link-text">
                                Dinner Table,<br/>
                                Meeting Lounge,<br/>
                                Banquet Hall<br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout>

);

export default SelfServiceTools;
